import { Component } from '@angular/core';
import { AdminService } from 'admin-api';
import { map, tap } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AdministrationService } from '../administration.service';
import { BehaviorSubject, of } from 'rxjs';
import { SubscriptionsService, Subscription, Terms, SubscriptionCreate } from 'api';

@Component({
  selector: 'app-subscriptions-page',
  templateUrl: './subscriptions-page.component.html',
  styleUrls: ['./subscriptions-page.component.scss'],
})
export class SubscriptionsPageComponent {
  constructor(
    private subscriptionsService: SubscriptionsService,
    private adminService: AdminService,
    private sharedService: SharedService,
  ) {}

  subscriptionEdit$ = new BehaviorSubject(null);

  subscriptions$ = this.subscriptionsService.getSubscriptions().pipe(
    map(subscriptions => {
      const subscriptionsForEdit: SubscriptionForEdit[] = subscriptions.data;
      subscriptionsForEdit.forEach(subscription => {
        subscription.tenantsForEdit = subscription.tenants?.map(tenant => ({ name: tenant }));
        subscription.termsForEdit = subscription.terms;
        if (subscription.termsForEdit) {
          subscription.termsForEdit.forEach(term => {
            term.offer_capabilitiesForEdit = Array.from(Object.entries(term.offer_capabilities), ([name, value]) => ({
              name,
              value,
            }));
          });
        }
      });
      return subscriptionsForEdit;
    }),
  );

  saveSubscription(subscription: SubscriptionForEdit) {
    const subscriptionClone = JSON.parse(JSON.stringify(subscription)) as SubscriptionForEdit;
    subscriptionClone.tenants = subscriptionClone.tenantsForEdit.map(tenant => tenant.name);
    delete subscriptionClone.tenantsForEdit;
    subscriptionClone.termsForEdit.forEach(term => {
      term.offer_capabilities = {};
      term['expiration_days'] = term.expiration; // TODO: API should rename this to be consistent
      term.deployment_sizes = ['test'];
      term.offer_capabilitiesForEdit.forEach(capability => (term.offer_capabilities[capability.name] = 'True'));
      delete term.offer_capabilitiesForEdit;
    });
    subscriptionClone.terms = subscriptionClone.termsForEdit;
    delete subscriptionClone.termsForEdit;
    const subscriptionForSave: SubscriptionCreate = {
      owner: subscriptionClone.username,
      tenants: subscriptionClone.tenants,
      terms: subscriptionClone.terms,
    };
    const saveCall =
      subscriptionClone.subscriptionid == ''
        ? this.subscriptionsService.createSubscription(subscriptionForSave)
        : this.subscriptionsService.updateSubscription(subscriptionClone.subscriptionid, subscriptionForSave);
    saveCall
      .pipe(
        tap(() => this.sharedService.showSuccess('Successfully updated subscription')),
        tap(() => this.sharedService.reloadCurrentRoute()),
        tap(() => this.subscriptionEdit$.next(null)),
        this.sharedService.catchError(),
      )
      .subscribe();
  }
  cancelClick() {
    this.subscriptionEdit$.next(null);
    this.sharedService.reloadCurrentRoute();
  }
}

export interface SubscriptionForEdit extends Subscription {
  termsForEdit?: Array<TermsForEdit>;
  tenantsForEdit?: Array<NamedEntry>;
}

export interface NamedEntry {
  name: string;
}
export interface TermsForEdit extends Terms {
  offer_capabilitiesForEdit?: Array<NamedEntry>;
}
